import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import { FeaturedProjectBySlugQuery } from '../../graphql-types'
import { Content } from '../components/styled'
import styled from 'styled-components'
import MarkdownRenderer from '../components/MarkdownRenderer'
import { next, prev } from '../Utils'

const renderWithComma = (arr: string[]) =>
  arr.map((txt, i) =>
    arr.length === i + 1 ? <span>{txt}</span> : <span>{txt}, </span>
  )

const FeaturedProjectTemplate = ({
  data,
  location,
}: {
  data: FeaturedProjectBySlugQuery
  location: Location
}) => {
  const siteTitle = get(data, 'site.siteMetadata.title')
  const post = get(data, 'contentfulBlogPost')

  return (
    <FeaturedProject>
      <Layout location={location}>
        <Content>
          <Helmet
            title={`${data.contentfulFeaturedProject.title} | ${siteTitle}`}
          />
          <div className="sidebar" style={{ padding: '0 1rem' }}>
            <p className="grey">
              <b>{data.contentfulFeaturedProject.title}</b>
            </p>
            <p>
              <span className="grey">
                Client <br />
              </span>
              {data.contentfulFeaturedProject.client &&
                data.contentfulFeaturedProject.client.map((s) => (
                  <div>{s}</div>
                ))}
            </p>
            <p>
              <span className="grey">
                Architect <br />
              </span>
              {data.contentfulFeaturedProject.architect.split(',,').map((s) => (
                <div>{s}</div>
              ))}
            </p>
            <p>
              <span className="grey">
                Local Authority <br />
              </span>
              {data.contentfulFeaturedProject.localAuthority
                .split(',,')
                .map((s) => (
                  <div>{s}</div>
                ))}
            </p>
            <p>
              <span className="grey">
                Category
                <br />
              </span>
              {data.contentfulFeaturedProject.category.map((s) => (
                <div>{s}</div>
              ))}
            </p>
            <p>
              <span className="grey">
                Status <br />
              </span>
              {data.contentfulFeaturedProject.status}
            </p>
          </div>
          <div>
            {data.contentfulFeaturedProject.text != null && (
              <MarkdownRenderer
                className="about-text"
                html={
                  data.contentfulFeaturedProject.text.childMarkdownRemark.html
                }
              />
            )}
            <br />
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '1rem',
              }}
            >
              <div>
                <Link
                  to={
                    '/' +
                    prev(
                      data.allContentfulFeaturedProject.edges
                        .sort(
                          (a, b) =>
                            Date.parse(b.node.date) - Date.parse(a.node.date)
                        )
                        .map(({ node }) => node.slug),
                      data.contentfulFeaturedProject.slug
                    )
                  }
                >
                  Previous Featured Project
                </Link>
              </div>
              <div>
                <Link
                  to={
                    '/' +
                    next(
                      data.allContentfulFeaturedProject.edges
                        .sort(
                          (a, b) =>
                            Date.parse(b.node.date) - Date.parse(a.node.date)
                        )
                        .map(({ node }) => node.slug),
                      data.contentfulFeaturedProject.slug
                    )
                  }
                >
                  Next Featured Project
                </Link>
              </div>
            </div>
          </div>
        </Content>

        {/* <div style={{ background: '#fff' }}>
        <div className={heroStyles.hero}>
          <Img
            className={heroStyles.heroImage}
            alt={post.title}
            fluid={post.heroImage.fluid}
          />
        </div>
        <div className="wrapper">
          <h1 className="section-headline">{post.title}</h1>
          <p
            style={{
              display: 'block',
            }}
          >
            {post.publishDate}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
        </div>
      </div> */}
      </Layout>
    </FeaturedProject>
  )
}

export default FeaturedProjectTemplate

export const pageQuery = graphql`
  query FeaturedProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulFeaturedProject {
      edges {
        node {
          slug
          title
          date
        }
      }
    }

    contentfulFeaturedProject(slug: { eq: $slug }) {
      id
      status
      text {
        text
        childMarkdownRemark {
          html
          htmlAst
        }
      }
      architect
      category
      date
      title
      localAuthority
      client
      slug
      createdAt
      title
      featuredImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

const FeaturedProject = styled.div``
